<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" sm="10" md="6">
        <img src="../../assets/img/brm.png" />
        <h1>SIN AUTORIZACIÓN</h1>
        <h2>Su token ha expirado, vuelva a iniciar sesión.</h2>
        <v-btn class="btn-main mt-3" block dark rounded @click="irLogin()" small
          >Ir a inicio de sesión</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  methods: {
    irLogin() {
      window.location.href = "https://login.okan.tools/login";
    },
  },
};
</script>
<style lang="less">
@import "../../assets/css/main";
</style>
